import { defineFormKitConfig } from '@formkit/vue'
import { de, fr, it, en } from '@formkit/i18n'
import {
  arrowDown,
  arrowUp,
  arrowLeft,
  arrowRight,
  check,
  circle,
  close,
  down,
  fileDoc,
  spinner,
} from '@formkit/icons'
import { rootClasses } from './formkit.theme'

const legends = ['checkbox_multi', 'radio_multi', 'repeater', 'transferlist']

function addAsteriskPlugin(node: any) {
  if (
    ['button', 'submit', 'hidden', 'group', 'list', 'meta'].includes(
      node.props.type,
    )
  )
    return

  node.on('created', () => {
    const legendOrLabel = legends.includes(
      `${node.props.type}${node.props.options ? '_multi' : ''}`,
    )
      ? 'legend'
      : 'label'

    if (node.props.definition.schemaMemoKey) {
      node.props.definition.schemaMemoKey += `${node.props.options ? '_multi' : ''}_add_asterisk`
    }

    const schemaFn = node.props.definition.schema
    node.props.definition.schema = (sectionsSchema: any = {}) => {
      sectionsSchema[legendOrLabel] = {
        children: [
          '$label',
          {
            $el: 'span',
            if: '$state.required',
            attrs: {
              class: '$classes.asterisk',
            },
            children: ['*'],
          },
        ],
      }

      return schemaFn(sectionsSchema)
    }
  })
}

export default defineFormKitConfig(() => {
  return {
    config: {
      rootClasses,
    },
    plugins: [addAsteriskPlugin],
    locales: { de, fr, it, en },
    locale: 'de',
    icons: {
      arrowDown,
      arrowUp,
      arrowLeft,
      arrowRight,
      close,
      checkboxDecorator: check,
      fileItem: fileDoc,
      fileRemove: close,
      noFiles: fileDoc,
      radioDecorator: circle,
      spinner,
      select: down,
    },
  }
})
